import { Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 's-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SPageHeaderComponent {
  @Input() pageTitle?: string;
  @Input() pageSubtitle?: string;
  @Input() pageButton?: {
    buttonClassName?: string;
    buttonLabel: string;
    icon?: any;
    iconUrl?: string;
    buttonType: | 'basic'
    | 'raised'
    | 'stroked'
    | 'flat'
    | 'icon'
    | 'fab'
    | 'mini-fab'
    | 'fab-extended'
  };

  @Output() buttonClick: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() {}

}
