<div class="page-header-container">
  <div class="page-header-section">
    <span *ngIf="pageTitle" class="page-header-title">
      {{ pageTitle | translate }}
    </span>
    <span *ngIf="pageSubtitle" class="page-heder-subtitle">
      {{ pageSubtitle | translate }}
    </span>
  </div>
  <s-button
    *ngIf="pageButton"
    [type]="pageButton.buttonType"
    [icon]="pageButton.icon"
    [iconUrl]="pageButton.iconUrl"
    [cssClass]="pageButton.buttonClassName"
    label= "{{ pageButton.buttonLabel | translate }}"
    (clickEvent)="buttonClick.emit($event)"
  ></s-button>
</div>

