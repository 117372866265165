import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 's-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class SButtonComponent {
  @Input() cssClass? = '';
  @Input() label = '';
  @Input() type:
    | 'basic'
    | 'raised'
    | 'stroked'
    | 'flat'
    | 'icon'
    | 'fab'
    | 'mini-fab'
    | 'fab-extended' = 'flat';
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';
  @Input() disabled = false;
  @Input() icon = '';
  @Input() isSuffix = false;
  @Input() iconUrl? = '';

  @Output() clickEvent: EventEmitter<Event> = new EventEmitter<Event>();
}
