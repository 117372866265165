import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SnackbarConfig } from '../../models/snackbar-config.model';
import { SnackbarType } from '../../models/snackbar-type.enum';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private readonly _isSnackbarActive: BehaviorSubject<boolean>;
  private _snackbarConfigObject?: SnackbarConfig;

  constructor() {
    this._isSnackbarActive = new BehaviorSubject<boolean>(false);
  }

  private _initSnackbarConfig() {
    this._snackbarConfigObject = {
      messageHeader: "SNACKBAR.ERROR_TITLE",
      messageContent: "SNACKBAR.ERROR_CONTENT",
      snackbarType: SnackbarType.error,
      snackbarButtons: {
        onConfirm: () => {},
        onCancel: () => {},
      },
    }
  }

  getIsSnackbarActive() {
    return this._isSnackbarActive.asObservable();
  }

  getSnackbarConfigObject() {
    return this._snackbarConfigObject;
  }

  setIsSnackbarActive(value: boolean, snackbarConfig?: SnackbarConfig) {
    this._initSnackbarConfig();

    if (value) {
      if (snackbarConfig) {
        this._snackbarConfigObject = snackbarConfig;
      } 

      this._isSnackbarActive.next(value);
      
      if (snackbarConfig?.snackbarType === SnackbarType.success || snackbarConfig?.snackbarType === SnackbarType.error) {
        setTimeout(() => {
          this.setIsSnackbarActive(false);
        }, 3000);
      }
    } else {
      this._isSnackbarActive.next(value);
    }
  }
}
