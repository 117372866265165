import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Utils } from 'src/app/utils/utils';


@Component({
  selector: 's-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
})
export class SInputTextComponent {
  @Input() cssClass? = '';
  @Input() placeholder = '';
  @Input() prefix?: string;
  @Input() hint?: string;
  @Input() label? = '';
  @Input() icon?: string;
  @Input() value?: string;
  @Input() control?: FormControl;
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() floatLabel = false;
  @Input() isPassword = false;
  @Input() clearable = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';

  @Output() changed: EventEmitter<string> = new EventEmitter<string>();
  @Output() iconClicked: EventEmitter<string> = new EventEmitter<string>();

  _showPassword = false;

  constructor(public utils: Utils) {
  }
}
