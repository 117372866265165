export enum EcuStatus {
    enabled = "enabled", 
    disabled = "disabled",
    pending = "pending",
    deleted = "deleted",
    created = "created",
    closed = "closed",
    rejected = "rejected",

    requestDisable = "requestDisable",
    requestEnable = "requestEnable",
    requestClose = "requestClose"
  }