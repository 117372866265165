import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { StorageClass } from './storage.model';
import { AuthService } from '../auth/auth.service';
import { Role } from '../../models/role.enum';
import { UserModel } from '../../models/user.model';
import { Utils } from 'src/app/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _store = new StorageClass();
  private _isReady: BehaviorSubject<boolean>;
  private _user: UserModel =
  {
    username: "guest",
    password: "password",
    role: Role.unauthorized,
    name: "anonymous",
    surname: "user"
  }
  ;
  private _userRole: Role;
  
  // isApprover : boolean = false;
  // isEngineer : boolean = false;

  constructor(readonly router: Router, private authService : AuthService, private utils: Utils) {
    this._isReady = new BehaviorSubject<boolean>(false);

    if (localStorage.getItem('store')) {
      this._store.setAll(JSON.parse(localStorage.getItem('store')!));
    }
    this._isReady.next(true);
    this._userRole = this.setUserRole();
  }

  getIsReadyObs() {
    return this._isReady.asObservable();
  }

  getCurrentRoute() {
    return this._store.getCurrentRoute();
  }

  setCurrentRoute(value: string) {
    this._store.setCurrentRoute(value);
    localStorage.setItem('store', JSON.stringify(this._store.getAll()));
  }

  clearAll() {
    this._store.clearStore();
    localStorage.clear();
    sessionStorage.clear();
    this._userRole = Role.unauthorized;
    this._user =   {
      username: "guest",
      password: "password",
      role: Role.unauthorized,
      name: "anonymous",
      surname: "user"
    }
  }

  getUserRole() {
    return this._userRole
  }
  
  setUserRole(value?: Role) {
    if (this.authService.getToken() == "approver" || value == "approver") {
      // this.isApprover = true;
      // this.isEngineer = false;
      return this._userRole = Role.approver
    }
    else if (this.authService.getToken() == "engineer" || value == "engineer") {
      // this.isApprover = false;
      // this.isEngineer = true;
      return this._userRole = Role.engineer
    } else {
      return this._userRole = Role.unauthorized
    }
  }

  getUser() {
    return this._user
  }

  setUser(user: UserModel) {
    this._user = user
  }

  getUserFullName() {
    return this.utils.capitalizeStartingLetters(`${this.getUser().name} ${this.getUser().surname}`)
  }

  getUserUsername() {
    return this._user.username
  }
}
