import { Injectable } from '@angular/core';
import { AppService } from '../../../app.service';
import { WebService } from 'src/app/shared/services/web-service/web.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { UserModel } from '../../../shared/models/user.model';
import { Router } from '@angular/router';
import { Role } from 'src/app/shared/models/role.enum';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenInterceptor } from 'src/app/interceptop';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends AppService {
  isDoubleRole = false;
  userDoesNotExist = false;
  user?: UserModel;

  // Delete all "fake backend" variables when we have ready the real backend
  usersBE: UserModel[] = [];

  constructor(
    override webService: WebService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private storageService: StorageService,
    private http: HttpClient,
    private tokenInterceptor: TokenInterceptor,
  ) {
    super(webService);
    this._getUsersBE();
  }

  async loginEvent(username: string, password: string) {
    // Implement real backend when is ready
    // const response: LoginModel = await this.webService.getService('/login/');

    this._checkUserBE(username, password);
  }

  // Delete all "fake backend" methods when we have ready the real backend
  private async _getUsersBE() {
    this.usersBE = await this.webService.getService(
      '/login-fake-BE/',
      undefined,
      undefined,
      true
    );
  }

  refreshToken(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', this.webService.TOKEN_REFRESH_AUTHORIZATION);
    headers = headers.append('Cookie', this.webService.XSRF_TOKEN);
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const url = this.webService.BASE_URL_TOKEN + "/v2/sdpr/oauth2/token";
    let body = new URLSearchParams();
    body.set('grant_type', 'client_credentials');
    body.set('scope', 'integration.fcl.fcagcv.com/ThunderManagement');
    return this.http.post(url, body.toString(), { headers: headers });
  }

  private _checkUserBE(username: string, password: string) {
    if (
      this._isUserPresentBE(username, password)
    ) {

      let user = this.usersBE.find(
        (user) => user.username === username && user.password === password
      );
      if (user!.role == 'approver') {
        //this._setApproverRoleBE();
        this.userDoesNotExist = false;
        this.isDoubleRole = true;
      } else if (user!.role == 'engineer') {
        this._setEngineerRoleBE();
        this.userDoesNotExist = false;
        this.isDoubleRole = false;
      } else {
        this.userDoesNotExist = true;
        setTimeout(() => {
          this.userDoesNotExist = false;
        }, 4000);
        console.log('identification failed');
      }
    } else {
      this.userDoesNotExist = true;
      setTimeout(() => {
        this.userDoesNotExist = false;
      }, 4000);
      console.log('login failed');
    }
  }

  private _isUserPresentBE(username: string, password: string): boolean {
    let user = this.usersBE.find(
      (user) => user.username === username && user.password === password
    );
    console.log("user", user)
    if (user) {
    this.user = user
    this.storageService.setUser(user!)
    this.tokenInterceptor.loginIntercept();
    }
    return !!user; //returns a boolean based on whether the user exists
  }

  // private _hasUserDoubleRoleBE(
  //   username: string,
  //   password: string
  // ): boolean {
  //   let user = this.usersBE.find(
  //     (user) => user.username === username && user.password === password
  //   );

  //   if (user) {
  //     if (user.role == "approver") {
  //       this.isDoubleRole = true;
  //       this.user = user;
  //       return true;
  //     } else {
  //       this.isDoubleRole = false;
  //       return false;
  //     }
  //   } else {
  //     this.isDoubleRole = false;
  //     return false;
  //   }
  // }

  //Update after BE implementation
  private _setEngineerRoleBE() {
    this.authService.setToken('engineer');
    // this.storageService.isEngineer = true;
    // this.storageService.isApprover = false;
    this.storageService.setUserRole(Role.engineer);
    this.router.navigate(['/dashboard']);
  }

  private _setApproverRoleBE() {
    this.authService.setToken('approver');
    // this.storageService.isEngineer = false;
    // this.storageService.isApprover = true;
    this.storageService.setUserRole(Role.approver);
    this.router.navigate(['/dashboard']);
  }

  //Remove after BE implementation
  setEngineerRoleBE() {
    this.authService.setToken('engineer');
    // this.storageService.isEngineer = true;
    // this.storageService.isApprover = false;
    this.storageService.setUserRole(Role.engineer);
  }

  setApproverRoleBE() {
    this.authService.setToken('approver');
    // this.storageService.isEngineer = false;
    // this.storageService.isApprover = true;
    this.storageService.setUserRole(Role.approver);
  }
}
