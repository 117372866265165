<div [class]="cssClass + ' buttonComponent'" [ngClass]="{'buttonComponent--disabled': disabled}">

  <button *ngIf="type==='basic'" mat-button [color]="color" [disabled]="disabled" (click)="clickEvent.emit($event)">{{label}}</button>

  <button *ngIf="type==='raised'" mat-raised-button [color]="color" [disabled]="disabled" (click)="clickEvent.emit($event)">{{label}}</button>

  <button *ngIf="type==='stroked'" mat-stroked-button [color]="color" [disabled]="disabled" (click)="clickEvent.emit($event)">{{label}}</button>

  <button *ngIf="type==='flat'" mat-flat-button [color]="color" [disabled]="disabled" (click)="clickEvent.emit($event)">{{label}}</button>

  <button *ngIf="type==='icon' && !isSuffix" mat-icon-button [color]="color" [disabled]="disabled" (click)="clickEvent.emit($event)">
    <mat-icon>{{icon}}</mat-icon>
  </button>

  <button *ngIf="type==='icon' && isSuffix" mat-icon-button matSuffix [color]="color" [disabled]="disabled" (click)="clickEvent.emit($event)">
    <mat-icon>{{icon}}</mat-icon>
  </button>

  <button *ngIf="type==='fab'" mat-fab [color]="color" [disabled]="disabled" (click)="clickEvent.emit($event)">
    <mat-icon>{{icon}}</mat-icon>
  </button>

  <button *ngIf="type==='fab-extended'" mat-fab extended [color]="color" [disabled]="disabled" (click)="clickEvent.emit($event)">
    <mat-icon *ngIf="!iconUrl?.length">{{icon}}</mat-icon>
    <img *ngIf="!icon?.length" [src]="iconUrl"/>
    {{label}}
  </button>

  <button *ngIf="type==='mini-fab'" mat-mini-fab [color]="color" [disabled]="disabled" (click)="clickEvent.emit($event)">
    <mat-icon>{{icon}}</mat-icon>
  </button>
</div>
