<div [class]="cssClass + ' buttonToggleComponent'">
  <label *ngIf="label">{{required ? label.concat('*') : label}}</label>

  <mat-button-toggle-group *ngIf="!control" [disabled]="disabled ? disabled : false" [multiple]="multiple" [name]="name ? name : ''" [vertical]="vertical" [(ngModel)]="valuesSelected" (change)="change.emit($event)">
    <mat-button-toggle *ngFor="let option of options" [value]="option.key" [disabled]="option.disabled" [ngClass]="{buttonToggleDisabled: option.disabled}" [hidden]="option.hidden">{{option.value}}</mat-button-toggle>
  </mat-button-toggle-group>

  <mat-button-toggle-group *ngIf="control" [disabled]="disabled ? disabled : false" [multiple]="multiple" [name]="name ? name : ''" [vertical]="vertical" [formControl]="control" (change)="change.emit(control.value)">
    <mat-button-toggle *ngFor="let option of options" [value]="option.key" [disabled]="option.disabled" [ngClass]="{buttonToggleDisabled: option.disabled}" [hidden]="option.hidden">{{option.value}}</mat-button-toggle>
  </mat-button-toggle-group>
</div>
