<div [class]="cssClass + ' selectGroupComponent'">
  <mat-grid-list [cols]="elements.length" rowHeight="120px">
    <div class="selectGroupComponent--label" [ngClass]="{'selectGroupComponent--disabled': disabled}">
      <h6 *ngIf="label">{{required ? label.concat('*') : label}}</h6>
    </div>

    <mat-grid-tile *ngFor="let element of elements; let i = index">
      <s-select
        [options]="element.options"
        [cssClass]="(i==0 && elements.length>1 ? 'firstEl' : i!=0 && elements.length-1==i ? 'lastEl' : 'middleEl')"
        [type]='element.type'
        [label]="element.label"
        [required]="element.required"
        [disabled]="disabled || element.disabled"
        [floatLabel]="element.floatLabel"
        [canFilter]="element.canFilter"
        [buttonToggleFilter]="element.buttonToggleFilter"
        [multiple]="element.multiple"
        [value]="element.value"
        [placeholder]="element.placeholder"
        [hint]="element.hint"
        [control]="element.control"
        [name]="element.name"
        (changed)="changedEvent($event, i)"
      ></s-select>
    </mat-grid-tile>
  </mat-grid-list>
</div>