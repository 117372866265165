import { Injectable } from '@angular/core';
import {
  FormControl,
  FormGroup,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class Utils {
  getFormValidationErrors(formGroup: FormGroup) {
    let totalErrors = 0;
    const errors: Array<{ key: string; keyError: string; error: string }> = [];

    Object.keys(formGroup.controls).forEach((key) => {
      const controlErrors: ValidationErrors | null = formGroup.get(key)!.errors;
      if (controlErrors != null) {
        totalErrors++;
        Object.keys(controlErrors).forEach((keyError) => {
          if (controlErrors[keyError]) {
            const error = {
              key,
              keyError,
              error: keyError,
            };
            errors.push(error);
          }
        });
      }
    });

    return {
      count: totalErrors,
      errors,
    };
  }

  getControlValidationErrors(
    formControl: FormControl,
    errorlabelCustom?: string
  ): { count: number; errors: Array<{ keyError: string; error: string }> } {
    let totalErrors = 0;
    const errors: Array<{ keyError: string; error: string }> = [];

    const controlErrors: ValidationErrors | null = formControl.errors;
    if (controlErrors != null) {
      totalErrors++;
      Object.keys(controlErrors).forEach((keyError) => {
        if (controlErrors[keyError]) {
          const error = {
            keyError,
            error: errorlabelCustom ? errorlabelCustom : keyError,
          };
          errors.push(error);
        }
      });
    }

    return {
      count: totalErrors,
      errors,
    };
  }

  getControlValidationErrorsLabel(
    formControl: FormControl,
    errorlabelCustom?: string
  ): string {
    const errors = this.getControlValidationErrors(
      formControl,
      errorlabelCustom
    );
    let errorsLabel = '';

    for (let index = 0; index < errors.errors.length; index++) {
      const element = errors.errors[index];

      if (!index) {
        errorsLabel = element.error;
      } else {
        errorsLabel = errorsLabel.concat(', ').concat(element.error);
      }
    }

    return errorsLabel;
  }

  capitalizeStartingLetters(input: string): string {
    if (!input) return '';
    return input
      .split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' '); // Split by space, Capitalize first letter, Add rest of the word to lower case, Join the words back together with spaces
  }
}

export function allFieldsRequired(controlNames: string[]): ValidatorFn {
  //this function requires the names of the fields to be validated as props.
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return {
        required: {
          message: 'Both fields are required',
        },
      };
    }

    const validatedCount = control.value.reduce((count: number, obj: any) => { 
      //"reduce" iterates through the elements (second argument) in control.value, 
      //and returns an accumulator (first argument) updated on the basis of the function we apply
      const allPropertiesFilled = controlNames.every((controlName: string) => {
        //"every" returns true if the all the elements satisfy the condition
        return (
          obj.hasOwnProperty(controlName) &&
          obj[controlName] !== null &&
          obj[controlName] !== undefined &&
          obj[controlName] !== ''
        );
      });
      if (allPropertiesFilled) {
        return count + 1;
      }
      return count;
    }, 0 /*=starting value for the count*/);

    if (validatedCount === control.value.length) {
      return null;
    } else {
      return {
        required: {
          message: 'Both fields are required',
        },
      };
    }
  };
}
