<div [class]="cssClass + ' textAreaComponent'"  [ngClass]="{'textAreaComponent--disabled': disabled, 'textAreaComponent--no-resize': !resize}">
  <h6 *ngIf="label && !floatLabel">{{required ? label.concat('*') : label}}</h6>

  <mat-form-field [appearance]="appearance">
    <mat-label *ngIf="label && floatLabel">{{required ? label.concat('*') : label}}</mat-label>

    <span *ngIf="prefix" matPrefix>{{prefix}} &nbsp;</span>
    <textarea *ngIf="control" matInput [placeholder]="placeholder" [formControl]="control" [readonly]="readonly" (input)="changed.emit(control.value)"></textarea>
    <textarea *ngIf="!control" matInput [placeholder]="placeholder" [(ngModel)]="value" [disabled]="disabled" [readonly]="readonly" (input)="changed.emit(value)"></textarea>

    <button *ngIf="value && clearable" matSuffix mat-icon-button (click)="value='';changed.emit(value)">
      <mat-icon [fontIcon]="'clear'"></mat-icon>
    </button>

    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
    <mat-error *ngIf="control && control.invalid">{{utils.getControlValidationErrorsLabel(control)}}</mat-error>

  </mat-form-field>
</div>

