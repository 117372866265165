<div [class]="cssClass + ' inputTextComponent'"  [ngClass]="{'inputTextComponent--disabled': disabled}">
  <h6 *ngIf="label && !floatLabel">{{required ? label.concat('*') : label}}</h6>

  <mat-form-field [appearance]="appearance">
    <mat-label *ngIf="label && floatLabel">{{required ? label.concat('*') : label}}</mat-label>

    <span *ngIf="prefix" matPrefix>{{prefix}} &nbsp;</span>
    <input *ngIf="control" matInput [type]="!_showPassword && isPassword ? 'password' : 'text'" [placeholder]="placeholder" [formControl]="control" [readonly]="readonly" (input)="changed.emit(control.value)"> <!-- originally, this line included [disabled]="control.disabled", but this gave waring messages in the console. this attribute should be set from the control that is passed-->
    <input *ngIf="!control" matInput [type]="!_showPassword && isPassword ? 'password' : 'text'" [placeholder]="placeholder" [(ngModel)]="value" [disabled]="disabled" [readonly]="readonly" (input)="changed.emit(value)">

    <button *ngIf="value && clearable" matSuffix mat-icon-button (click)="value='';changed.emit(value)">
      <mat-icon [fontIcon]="'clear'"></mat-icon>
    </button>

    <button *ngIf="icon" matSuffix mat-icon-button (click)="iconClicked.emit(value)">
      <mat-icon [fontIcon]="icon"></mat-icon>
    </button>

    <button *ngIf="isPassword" matSuffix mat-icon-button (click)="_showPassword=!_showPassword">
      <mat-icon [fontIcon]="_showPassword ? 'visibility' : 'visibility_off'"></mat-icon>
    </button>

    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
    <mat-error *ngIf="control && control.invalid">{{utils.getControlValidationErrorsLabel(control)}}</mat-error>

  </mat-form-field>
</div>

