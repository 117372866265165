export const environment = {
  production: false,
  defaultLang: 'en',
  mocked: false,
  here: {
    apikey: 'UOQuu1alZ6gXYBKHBslj4PIV6I6Yi2v2DKEyOfY8', //TODO: Rimuovere chiave Mapbox prima di rilasciare
  },
  backend: {
    baseURL: 'https://entp.fcl-02.intg.fcagcv.com',
    baseURLToken: 'https://identity.fcl-02.intg.fcagcv.com',
    tokenRefresh: 'Basic M3A0ZDBmbWtqcjdyaG1qM2dlbm43NmQ5bWw6aTJnODZpbmdncGExaXVkc2hsY2VnMzR2MDhlMGQ0dm05azNnNTdwM3Btb3V2MmRzNTU2',
    xsrfToken: 'XSRF-TOKEN=d59e285c-ce38-4da9-ace7-05c211e10ec4',  
    apikey: 'VyogZVzrgs7MYojPkEYhKauRiYRwa3w63nPdgkJI',
    token: ''
  },
  login: {
    adfsUrl_production: 'https://fedgsdp01-dev.fcagroup.com', //'https://fed04.fcagroup.com';
    adfsUrl_certification: 'https://fedgsdp01-dev.fcagroup.com', //'https://fed04.fcagroup.com';
    application_redirect_url: 'https://autodrive-roadclearance.fcl-02.intg.fcagcv.com/loginCallback', //'https://autodrive-roadclearance.fcl-02.intg.fcagcv.com/loginCallback'
    client_id: 'c9957400-3b1b-4434-887a-66682bf17bad', 
    resource_id: 'RC_NAFTA_INT',
    client_secret: '',
    userInfo: 'https://gap-ext.fcl-01.intg.fcagcv.com/usersSearch', //temptative one
  },
};


//TELEMATICS
// export const environment = {
//     production: false,
//     defaultLang: 'en',
//     mocked: false,
//     here: {
//       apikey: 'VyogZVzrgs7MYojPkEYhKauRiYRwa3w63nPdgkJI', //TODO: Rimuovere chiave Mapbox prima di rilasciare
//     },
//     backend: {
//       apikey: 'VyogZVzrgs7MYojPkEYhKauRiYRwa3w63nPdgkJI',
//       baseURL: 'https://telematics.fcl-02.intg.intra.fcagcv.com', 
//       baseURLToken: 'https://identity.fcl-02.intg.fcagcv.com',
//       token: '',
//       tokenRefresh: 'Basic M3A0ZDBmbWtqcjdyaG1qM2dlbm43NmQ5bWw6aTJnODZpbmdncGExaXVkc2hsY2VnMzR2MDhlMGQ0dm05azNnNTdwM3Btb3V2MmRzNTU2',
//       xsrfToken: 'XSRF-TOKEN=758c3dda-0cb5-4f7b-912a-3f7a1ab00bc6'
//     },
//   };

//clientId:  2nt7a9haet2slu7q725itv006f
//clientSecret 1tb50se8q6f6t9vl10du7594r2dfjv3eh4ie0of3q2tde1b2nbhg