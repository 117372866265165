import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StorageService } from '../../services/storage/storage.service';
import { DashboardService } from 'src/app/modules/dashboard/service/dashboard.service';
import { DialogService } from './dialog.service';
import { PatchObject } from 'src/app/modules/dashboard/view/pages/review-page/model/patch-object.model';
import { PatchOperation } from 'src/app/modules/dashboard/view/pages/review-page/model/patch-operation.enum';

@Component({
  selector: 's-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class SDialogComponent implements OnInit, OnDestroy {
  pageTitle: string = 'COMMON.EXCEPTION';
  pageSubtitle: string = "";
  logo: string = "";
  isWarning: boolean = false;
  isReject: boolean = false;
  isClosure: boolean = false;
  details: string = '';

  patchObject: PatchObject = {
    userName: '',
    userFullName: '',
    details: '',
  };

  constructor(
    public dialogRef: MatDialogRef<SDialogComponent>,
    public storageService: StorageService,
    public dashboardService: DashboardService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    console.log("data", this.data)
    this.pageSubtitle = this.data.exceptionDetails.exception;
    let make = this.data.table.brand.make;
    this.logo = this.dashboardService.getLogo(make);
    this.patchObject = this.dialogService.setPatchObject();
    console.log("data", this.dashboardService.getReviewObj());
    console.log("isWarning", this.isWarning);
    console.log("isReject", this.isReject);
    console.log("ecuStatus", this.dashboardService.getEcuStatus())
    console.log("patchObj", this.patchObject)
  }

  getDidsKeys() {
    return Object.keys(this.dashboardService.getReviewObj().dids);
  }

  onClose(): void {
    console.log("onClose")
    this.dialogRef.close();
    this.isWarning = false;
    this.isReject = false;
    this.isClosure = false;
  }

  openCancel() {
    console.log("openCancel ecuStatus", this.dashboardService.getEcuStatus())
    console.log("openCancel reviewObj", this.dashboardService.getReviewObj())
    console.log("openCancel dialog data", this.data)
    if(!this.isWarning && this.storageService.getUserRole() == 'approver') {
      console.log("openCancel approver not warning, isReject, isWarning, ecuStatus", this.isReject, this.isWarning, this.dashboardService.getEcuStatus())
      this.isReject = true;
      this.isWarning = true;
    } else if(!this.isWarning && this.storageService.getUserRole() == 'engineer') {
      console.log("openCancel engineer not warning, isReject, isWarning, ecuStatus", this.isReject, this.isWarning, this.dashboardService.getEcuStatus())
      this.onClose();
    } else {
      console.log("openCancel warning, isReject, isWarning, ecuStatus", this.isReject, this.isWarning, this.dashboardService.getEcuStatus())
      this.isWarning = false;
      this.isReject = false;
      this.isClosure = false;
    }
  }
  openCloseException() {
    console.log("openClose, isReject, isWarning, ecuStatus", this.isReject, this.isWarning, this.dashboardService.getEcuStatus())
    this.isWarning = true;
    this.isReject = false;
    this.isClosure = true;
  }
  openConfirm(reviewObj: any) {
    if(!this.isWarning) {
      console.log("openConfirm not warning, isReject, isWarning, ecuStatus, patchObj", this.isReject, this.isWarning, this.dashboardService.getEcuStatus(), this.patchObject.details)
      this.isWarning = true;
    } else {
      if(this.storageService.getUserRole() == 'approver') {
        console.log("openConfirm approver not warning, isReject, isWarning, ecuStatus", this.isReject, this.isWarning, this.dashboardService.getEcuStatus())
        if(!this.isReject) {
          console.log("confirm approver approve")
          this.dialogService.patchExceptionBE(
            this.patchObject,
            this.dashboardService.getReviewObj().id!,
            PatchOperation.approve
          );
        } else {
          console.log("confirm approver reject")
          this.dialogService.patchExceptionBE(
            this.patchObject,
            this.dashboardService.getReviewObj().id!,
            PatchOperation.reject
          );
        }
      } else if (this.storageService.getUserRole() == 'engineer'){
        console.log("openConfirm engineer not warning, isReject, isWarning, ecuStatus", this.isReject, this.isWarning, this.dashboardService.getEcuStatus())

        if((this.dashboardService.getEcuStatus() == 'disabled'  
          || this.dashboardService.getEcuStatus() == 'requestEnable'  
          || this.dashboardService.getEcuStatus() == 'requestClose')
          && this.data.exceptionDetails.enabled == '---'  
          && !this.isClosure) {
            console.log("confirm engineer enable")
            this.dialogService.patchExceptionBE(
              this.patchObject,
              this.dashboardService.getReviewObj().id!,
              PatchOperation.enable
            );
        } else if((this.dashboardService.getEcuStatus() == 'enabled' 
          || this.dashboardService.getEcuStatus() == 'requestDisable'
          || this.dashboardService.getEcuStatus() == 'requestClose')
          && this.data.exceptionDetails.disabled == '---'   
          && !this.isClosure) {
            console.log("confirm engineer disable")
            this.dialogService.patchExceptionBE(
              this.patchObject,
              this.dashboardService.getReviewObj().id!,
              PatchOperation.disable
            );
        } else if ((this.dashboardService.getEcuStatus() == 'enabled' 
          || this.dashboardService.getEcuStatus() == 'disabled' 
          || this.dashboardService.getEcuStatus() == 'requestEnable'  
          || this.dashboardService.getEcuStatus() == 'requestDisable'
          || this.dashboardService.getEcuStatus() == 'requestClose')  
          && this.isClosure) {
            console.log("confirm engineer close")
            this.dialogService.patchExceptionBE(
              this.patchObject,
              this.dashboardService.getReviewObj().id!,
              PatchOperation.close
            );
        }

      }
      this.onClose();
    }
  }

  ngOnDestroy(): void {
  }
}