import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Utils } from 'src/app/utils/utils';


@Component({
  selector: 's-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class SCheckboxComponent {
  @Input() cssClass? = '';
  @Input() label = '';
  @Input() required = false;
  @Input() disabled = false;
  @Input() value = false;
  @Input() indeterminate = false;
  @Input() labelPosition: 'after' | 'before' = 'after';
  @Input() hint?: string;
  @Input() control?: FormControl;

  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public utils: Utils) {}
}
