import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Utils } from 'src/app/utils/utils';


@Component({
  selector: 's-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})
export class STextAreaComponent {
  @Input() cssClass? = '';
  @Input() placeholder = '';
  @Input() prefix?: string;
  @Input() hint?: string;
  @Input() label? = '';
  @Input() value?: string;
  @Input() control?: FormControl;
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() floatLabel = false;
  @Input() clearable = false;
  @Input() resize = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';

  @Output() changed: EventEmitter<string> = new EventEmitter<string>();

  constructor(public utils: Utils) {}
}
