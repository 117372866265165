import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SInputTextComponent } from './input-text/input-text.component';
import { SCheckboxComponent } from './checkbox/checkbox.component';
import { SLogoComponent } from './logo/logo.component';
import { SButtonComponent } from './button/button.component';
import { SSnackbarComponent } from './snackbar/snackbar.component';
import { SSpinnerComponent } from './spinner/spinner.component';
import { SSelectComponent } from './select/select.component';
import { SSelectGroupComponent } from './select-group/select-group.component';
import { SButtonToggleComponent } from './button-toggle/button-toggle.component';
import { SPageHeaderComponent } from './page-header/page-header.component';
import { SDialogComponent } from './dialog/dialog.component';

import { TranslateModule } from '@ngx-translate/core';
import { STextAreaComponent } from './text-area/text-area.component';
import { SPageFooterComponent } from './page-footer/page-footer.component';

const component = [
  SInputTextComponent,
  SCheckboxComponent,
  SLogoComponent,
  SButtonComponent,
  SSnackbarComponent,
  SSpinnerComponent,
  SSelectComponent,
  SSelectGroupComponent,
  SButtonToggleComponent,
  SPageHeaderComponent,
  STextAreaComponent,
  SPageFooterComponent,
  SDialogComponent,
];

const materialModule = [
  MatButtonModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatListModule,
  MatTableModule,
  MatSelectModule,
  MatGridListModule,
  MatButtonToggleModule,
  MatDialogModule,
  MatTooltipModule,
];

const otherModule = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
]

@NgModule({
  declarations: component,
  imports: [
    ...otherModule,
    ...materialModule,
    TranslateModule
  ],
  providers: [],
  exports: [...component, ...otherModule, ...materialModule, TranslateModule]
})
export class SharedComponentsModule {}
