import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  constructor(private translate: TranslateService) {}

  initI18nService() { // to use in AppComponent
    // L'ordine è importante
    /** 1. setta le lingue */
    this._setLangs();
    /** 2 setta la lingua del browser come di default */
    this._setDefaultLang();
    /** 3 setta come in uso la lingua di default*/
    this.setCurrentLang(this.getDefaultLang());
  }

  private _setLangs() {
    this.translate.addLangs(['en']);
  }

  /**
 * @returns (string[]) Ritorna la lista di lingue disponibili.
 */
  getLangs(): string[] {
    return this.translate.getLangs();
  }

  /* getBrowserLanguage() {
    const navigator = window.navigator;
    if (navigator && navigator.language) {
      return navigator.language.split('-')[0];
    } else {
      return environment.defaultLang;
    }
  } */

  private _setDefaultLang() {
    this.translate.setDefaultLang(environment.defaultLang);
  }

  /**
   * @returns (string) Ritorna la lingua di default.
   */
  getDefaultLang(): string {
    return this.translate.getDefaultLang();
  }

  /**
   * @param lang Lingua da attivare.
   */
  setCurrentLang(lang: string) {
    this.translate.use(lang);
  }

  /**
   * @returns (string) Ritorna la lingua attualmente attiva.
   */
  getCurrentLang(): string {
    return this.translate.currentLang;
  }

}
