import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { StorageService } from './storage/storage.service';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private _isReady = false;

  constructor(
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly authService: AuthService) {

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.storageService.getIsReadyObs().subscribe(ready => {
            if (ready) {
              if (!this._isReady) {
                // NavigationService init

                const currentRoute = this.storageService.getCurrentRoute();
                
                if (this.authService.isLogged()) {
                  // user logged

                  if (currentRoute === '/' || !currentRoute) {
                    this.router.navigate(['/dashboard']);
                    this.storageService.setCurrentRoute('/dashboard');
                  } else {
                    this.router.navigate([currentRoute]);
                  }
                } else {
                  // user not logged

                  this.router.navigate(['/login']);
                  this.storageService.setCurrentRoute('/login');
                }

                this._isReady = true;
              } else {
                // triggered by every router change

                this.storageService.setCurrentRoute(event.url);
              }
              
            }
          });
        }        
      })
    }

}
