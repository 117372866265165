import { Injectable, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, lastValueFrom } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { WebService } from 'src/app/shared/services/web-service/web.service';
import { EcuStatus } from '../model/ecuStatus.enum';
import { ReviewObject } from '../view/pages/review-page/model/review-object.model';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { SelectInterface } from 'src/app/shared/components/select/select.component';
import { environment } from 'src/environments/environment';
import { EditObject } from 'src/app/modules/dashboard/view/pages/review-page/model/edit-object.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends AppService implements OnInit {
  private _isEditMode: boolean = false;
  private _isBackFromReview: boolean = false;
  private _isAddNewFromTable: boolean = false;
  private _ecuStatus: EcuStatus = EcuStatus.pending;

  private _isViewAllECUs: BehaviorSubject<boolean>;
  private _pageSubtitle: BehaviorSubject<string>;

  ecuFilters = [
    { text: 'mpad', selected: true },
    { text: 'View All', selected: false },
  ];
  ecuFilterOptions: SelectInterface[] = [];

  private _BEdidList: { key: string; value: string }[] = [];
  private _didList: { [key: string]: string } = {
    F132: '',
    F191: '',
    F188: '',
    F187: '',
    F18A: '',
    F18B: '',
  };
  private _issueTypes: SelectInterface[] = [
    { key: 'hardware', value: 'Hardware' },
    { key: 'software', value: 'Software' },
  ];

  private _reviewObj: ReviewObject = {
    vehicles: [
      {
        make: '',
        models: [
          {
            name: '',
            years: [],
          },
        ],
        logo: '',
      },
    ],
    ecu: '',
    issueType: [],
    addedByFullName: '',
    addedByUsername: '',
    dids: this.getDidList(),
  };

  private _editObj: EditObject = {
    step1: undefined,
    step2: undefined,
    step3: undefined,
    step4: undefined,
    step5: undefined,
    id: undefined,
  };

  constructor(
    override webService: WebService,
    public storageService: StorageService
  ) {
    super(webService);
    this._isViewAllECUs = new BehaviorSubject<boolean>(false);
    this._pageSubtitle = new BehaviorSubject<string>('');
    this.getDidListBE();
    this._getEcusFiltersBE();
  }

  ngOnInit(): void {}

  //EDIT MODEs___________________________________________________________
  getisEditMode() {
    return this._isEditMode;
  }
  setEditMode(value: boolean) {
    this._isEditMode = value;
  }

  getIsBackFromReview() {
    return this._isBackFromReview;
  }
  setIsBackFromReview(value: boolean) {
    this._isBackFromReview = value;
  }

  getIsAddNewFromTable() {
    return this._isAddNewFromTable;
  }
  setIsAddNewFromTable(value: boolean) {
    this._isAddNewFromTable = value;
  }

  //ECU STATUS_________________________________________________________
  getEcuStatus() {
    return this._ecuStatus;
  }
  setEcuStatus(value: EcuStatus) {
    this._ecuStatus = value;
  }

  //VIEW _ALL__________________________________________________________
  getIsViewAllECUs() {
    return this._isViewAllECUs.asObservable();
  }
  setIsViewAllECUs(value: boolean) {
    this._isViewAllECUs.next(value);
  }

  //PAGE SUBTITLE______________________________________________________
  getPageSubtitle() {
    return this._pageSubtitle.asObservable();
  }
  setPageSubtitle(value: string) {
    this._pageSubtitle.next(value);
  }

  //EDIT OBJ____________________________________________________________
  getEditObj() {
    return this._editObj;
  }
  setEditObj(editObj: EditObject) {
    this._editObj = editObj;
  }
  clearEditObj() {
    this._editObj = {
      step1: undefined,
      step2: undefined,
      step3: undefined,
      step4: undefined,
      step5: undefined,
      id: undefined,
    };
  }
  // //TODO: wait for confirmation of data structure before deleting. This function handles models and years of type array
  // getVehiclesConfiguration(table: any) {
  //   let models: string[] = [];
  //   table.brand.models.forEach((model: string) => {
  //     let formattedModel = table.brand.make
  //       .toUpperCase()
  //       .concat('_')
  //       .concat(model.toUpperCase());
  //     models.push(formattedModel);
  //   });
  //   let years: string[] = [];
  //   models.forEach((model: string) => {
  //     table.brand.years.forEach((modelYear: string) => {
  //       let formattedModelYear = model
  //         .toUpperCase()
  //         .concat('_')
  //         .concat(modelYear.toUpperCase());
  //       years.push(formattedModelYear);
  //     });
  //   });
  //   let vehicles = {
  //     makes: [table.brand.make.toUpperCase()],
  //     models: models,
  //     years: years,
  //   };
  //   return vehicles;
  // }

  //this version handles models and years of type string
  getVehiclesConfiguration(table: any) {
    let models: string[] = [];
    table.brand.model.split(', ').forEach((model: string) => {
      let formattedModel = table.brand.make
        .toUpperCase()
        .concat('_')
        .concat(model.toUpperCase());
      models.push(formattedModel);
    });
    let years: string[] = [];
    models.forEach((model: string) => {
      table.brand.year.split(', ').forEach((modelYear: string) => {
        let formattedModelYear = model
          .toUpperCase()
          .concat('_')
          .concat(modelYear.toUpperCase());
        years.push(formattedModelYear);
      });
    });
    let vehicles = {
      makes: [table.brand.make.toUpperCase()],
      models: models,
      years: years,
    };
    return vehicles;
  }

  //ISSUE TYPES______________________________________________
  getIssueTypeConfiguration() {
    return this._issueTypes;
  }

  //REVIEW OBJ_______________________________________________
  getReviewObj() {
    return this._reviewObj;
  }
  setReviewObj(reviewObj: ReviewObject) {
    this._reviewObj = reviewObj;
  }
  clearReviewObj() {
    this.clearDidList();
    this._reviewObj = {
      vehicles: [
        {
          make: '',
          models: [
            {
              name: '',
              years: [],
            },
          ],
          logo: '',
        },
      ],
      ecu: '',
      issueType: [],
      addedByFullName: '',
      addedByUsername: '',
      dids: this.getDidList(),
    };
  }

  //Logo_____________________________________________________________
  getLogo(make: string) {
    let logo: string = '';
    switch (
      make
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .replaceAll(' ', '')
    ) {
      case 'jeep':
        logo = '/assets/icons/jeep.svg';
        break;
      case 'alfaromeo':
        logo = '/assets/icons/alfa-romeo.svg';
        break;
      case 'abarth':
        logo = 'assets/icons/abarth.svg';
        break;
      case 'maserati':
        logo = 'assets/icons/maserati.svg';
        break;
      default:
        logo = '';
    }
    return logo;
  }

  //DIDs______________________________________________________________________
  async getDidListBE() {
    const testingMockBE = true;
    const path =
      !environment.mocked && testingMockBE
        ? '/v1/feature-audit/did'
        : '/did-list/';
    const response = await this.webService.getService(
      path,
      undefined,
      undefined,
      testingMockBE ? environment.mocked : true
    );
    const formattedResponse = response.map(
      (object: { key: string; description: string; type: string }) => {
        return {
          ...object,
          key: object.key.toUpperCase(),
          value: object.key.toUpperCase(),
        };
      }
    );
    formattedResponse.forEach((object:  { key: string; value: string; description?: string; type?: string; }) => {
      delete object.description;
      delete object.type;
    });
    this._BEdidList = formattedResponse;
    this.buildDidList(response);
    return formattedResponse as SelectInterface[];
  }
  getBEdids() {
    return this._BEdidList;
  }
  buildDidList(elements: { key: string; description: string }[]) {
    let emptyDidList: {} = {};
    elements.map((element: { key: string; description: string }) => {
      let did = element.key.toUpperCase();
      emptyDidList = {
        ...emptyDidList,
        [did]: '',
      };
    });
    return emptyDidList;
  }
  clearDidList() {
    let clearedDidList:{ [key: string]: string } = {};
    this._BEdidList.forEach((el: any) => {
      clearedDidList[el.key] = '';
    });
    this._didList = clearedDidList;
    return clearedDidList;
  }
  getDidList() {
    return this._didList;
  }
  setDidList(didList: { [key: string]: string }) {
    this._didList = didList;
  }

  getDidConfiguration(exceptionDetails: any) {
    let didList = this.getDidList();
    let formDids = [];

    if (Array.isArray(exceptionDetails)) {
      exceptionDetails.forEach((exception: any) => {
        const keys = Object.keys(exception);
        for (const key of keys) {
          if (key.toUpperCase() in didList) {
            if (exception[key] != '') {
              formDids.push({
                name: exception[key],
                selection: key.toUpperCase(),
              });
            }
          }
        }
      });
    } else {
      const keys = Object.keys(exceptionDetails);
      for (const key of keys) {
        if (key.toUpperCase() in didList) {
          if (exceptionDetails[key] != '') {
            formDids.push({
              name: exceptionDetails[key],
              selection: key.toUpperCase(),
            });
          }
        }
      }
    }
    return formDids;
  }

  //ECUs__________________________________________________________________________
  private async _getEcusFiltersBE() {
    const testingMockBE = true;
    const path =
      !environment.mocked && testingMockBE
        ? '/v1/feature-audit/ecu'
        : '/ecu-list/';
    await this.webService
      .getService(
        path,
        undefined,
        undefined,
        testingMockBE ? environment.mocked : true
      )
      .then((response: { key: string; description: string; type: string }[]) => {
        this.ecuFilters = response.map(
          (
            element: { key: string; description: string; type: string },
            index: number
          ) => {
            return {
              text: element.key,
              selected: index == 0 ? true : false,
              key: element.key.toUpperCase(),
              value: element.key.toUpperCase,
            };
          }
        );
        this.ecuFilters.push({
          text: 'View All',
          selected: false,
        });
      });
  }
  async getEcusFiltersOptionsBE(): Promise<SelectInterface[]> {
    const testingMockBE = true;
    const path =
      !environment.mocked && testingMockBE
        ? '/v1/feature-audit/ecu'
        : '/ecu-list/';
    const response = await this.webService.getService(
      path,
      undefined,
      undefined,
      testingMockBE ? environment.mocked : true
    );

    const formattedResponse: SelectInterface[] = response.map(
      (
        element: { key: string; description: string; type: string },
        index: number
      ) => {
        return {
          key: element.key.toUpperCase(),
          value: element.key.toUpperCase(),
        };
      }
    );
    return formattedResponse as SelectInterface[];
  }

  //Clear Dashboard Data_______________________________________________________________
  clearAllDashboardData() {
    this.clearDidList();
    this.clearEditObj();
    this.clearReviewObj();
    this.setEditMode(false);
    this.setIsBackFromReview(false);
    this.setIsAddNewFromTable(false);
    this.setIsViewAllECUs(false);
    this.setEcuStatus(EcuStatus.pending);
    this.setPageSubtitle('');
  }
}
