/*
* Authentication Service
*
* This service manage all the interaction with the User Token
*/

import {Injectable} from '@angular/core';

type Token = string;
type NullableToken = Token | undefined;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  protected token: NullableToken;

  constructor() {
    this.token = this.readToken();
  }

  /*
  * Token Setter
  * @param {string} token  Token to store
  */

  setToken(token: string) {
    this.token = token;
    this.storeToken(token);
  }

  /*
  * Token Getter
  * @returns Token stored in controller
  */

  getToken(): NullableToken {
    return this.token;
  }

  /*
  * Method that check if the Token is still valid for the current session
  * @returns status of the token (true == Logged, false == Not Logged)
  */

  isLogged(): boolean {
    return typeof this.token == 'string' && this.token.length > 0;
  }

  /*
  * Method that get the Token in the Local Storage
  * @returns Token stored in Local Storage
  */

  protected readToken(): string | undefined {
    return sessionStorage.getItem('sut')?.toString();
  }

  /*
  * Method that set the Token in the Local Storage
  * @param {string} token  Token to store
  */

  protected storeToken(token: string) {
    sessionStorage.setItem('sut', token);
  }
}
