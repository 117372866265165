import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { SnackbarService } from './snackbar.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SSnackbarComponent implements OnInit {

  //dynamic classes
  fillerClass: string[] = ["snackbar-filler", "primary-background-snackbar"];
  contentContainerClass = ["snackbar-success-content-container"];
  messageHeaderClass: string[] = ["snackbar-message-header"]
  cancelSButtonClass: string = "snackbar-button white-background";
  confirmSButtonClass: string = "snackbar-button white-background";
  cancelActionName: string = "COMMON.CANCEL_BUTTON";
  confirmActionName: string =  "COMMON.CONFIRM_BUTTON";

  constructor(public snackbarService: SnackbarService) {
  }

  ngOnInit(): void {
    //functions to set dynamic classes based on booleans for dynamic styles
    if (this.snackbarService.getSnackbarConfigObject()?.snackbarType === "success") {
      this.setSuccessStyle();
    }
    if (this.snackbarService.getSnackbarConfigObject()?.snackbarType === "error") {
      this.setErrorStyle();
    }
    if (this.snackbarService.getSnackbarConfigObject()?.snackbarType === "confirm") {
      this.setConfirmStyle();
    }
    if (this.snackbarService.getSnackbarConfigObject()?.snackbarType === "warning") {
      this.setWarningStyle();
    }
    if (this.snackbarService.getSnackbarConfigObject()?.snackbarType === "approve") {
      this.setApproveStyle();
    }
    if (this.snackbarService.getSnackbarConfigObject()?.snackbarType === "reject") {
      this.setRejectStyle();
    }
    if (this.snackbarService.getSnackbarConfigObject()?.snackbarType === "enable") {
      this.setEnableStyle();
    }
    if (this.snackbarService.getSnackbarConfigObject()?.snackbarType === "disable") {
      this.setDisableStyle();
    }
  }

  setSuccessStyle() {
    this.fillerClass = ["snackbar-filler", "primary-background-snackbar"];
    this.contentContainerClass = ["snackbar-content-container"];
    this.messageHeaderClass = ["snackbar-message-header", "blue-text", "compact-title-margins"]
  }
  setErrorStyle() {
    this.fillerClass = ["snackbar-filler", "orange-background-snackbar"];
    this.contentContainerClass = ["snackbar-content-container"];
    this.messageHeaderClass = ["snackbar-message-header", "orange-text", "compact-title-margins"]
  }
  setConfirmStyle() {
    this.confirmActionName = "COMMON.CONTINUE_BUTTON";
    this.confirmSButtonClass = "snackbar-button primary-background";
    this.fillerClass = ["snackbar-filler", "primary-background-snackbar"];
    this.contentContainerClass = ["snackbar-content-container"];
    this.messageHeaderClass = ["snackbar-message-header", "blue-text"]
  }
  setWarningStyle() {
    this.confirmActionName = "COMMON.CONTINUE_BUTTON";
    this.confirmSButtonClass = "snackbar-button primary-background";
    this.fillerClass = ["snackbar-filler", "orange-background-snackbar"];
    this.contentContainerClass = ["snackbar-content-container"];
    this.messageHeaderClass = ["snackbar-message-header", "orange-text"]
  }
  setApproveStyle() {
    this.confirmActionName = "COMMON.APPROVE_BUTTON";
    this.confirmSButtonClass = "snackbar-button green-background";
    this.fillerClass = ["snackbar-filler", "primary-background-snackbar"];
    this.contentContainerClass = ["snackbar-content-container"];
    this.messageHeaderClass = ["snackbar-message-header", "blue-text"]
  }
  setRejectStyle() {
    this.confirmActionName = "COMMON.REJECT_BUTTON";
    this.confirmSButtonClass = "snackbar-button red-background";
    this.fillerClass = ["snackbar-filler", "primary-background-snackbar"];
    this.contentContainerClass = ["snackbar-content-container"];
    this.messageHeaderClass = ["snackbar-message-header", "blue-text"]
  }
  setEnableStyle() {
    this.confirmActionName = "COMMON.ENABLE_BUTTON";
    this.confirmSButtonClass = "snackbar-button green-background";
    this.fillerClass = ["snackbar-filler", "primary-background-snackbar"];
    this.contentContainerClass = ["snackbar-content-container"];
    this.messageHeaderClass = ["snackbar-message-header", "blue-text"]
  }
  setDisableStyle() {
    this.confirmActionName = "COMMON.DISABLE_BUTTON";
    this.confirmSButtonClass = "snackbar-button red-background";
    this.fillerClass = ["snackbar-filler", "primary-background-snackbar"];
    this.contentContainerClass = ["snackbar-content-container"];
    this.messageHeaderClass = ["snackbar-message-header", "blue-text"]
  }


  openCancel() {
    this.snackbarService.getSnackbarConfigObject()?.snackbarButtons?.onCancel();
    this.snackbarService.setIsSnackbarActive(false);
  }
  openConfirm(object?: any) {
    this.snackbarService.getSnackbarConfigObject()?.snackbarButtons?.onConfirm(object);
  }
}

