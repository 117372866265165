<ng-container *ngIf="overlay;else progressSpinner">
  <div class="overlay">
    <div class="center loader">
      <ng-template [ngTemplateOutlet]="progressSpinner"></ng-template>
    </div>
  </div>
</ng-container>
<ng-template #progressSpinner>
  <mat-progress-spinner
    [color]="color"
    [value]="value"
    [mode]="mode">
  </mat-progress-spinner>
</ng-template>

