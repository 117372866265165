import {Component, Input} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SSpinnerComponent {

  @Input() value = 50;
  @Input() mode: ProgressSpinnerMode = 'indeterminate';
  @Input() overlay = false;
  @Input() color: ThemePalette = 'accent';

}
