import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SpinnerService } from './shared/components/spinner/spinner.service';
import { NavigationService } from './shared/services/navigation.service';
import { I18nService } from './shared/services/18n.service';
import { SnackbarConfig } from './shared/models/snackbar-config.model';
import { SnackbarType } from './shared/models/snackbar-type.enum';
import { AppService } from './app.service';
import { SnackbarService } from './shared/components/snackbar/snackbar.service';
import { TokenInterceptor } from './interceptop';
import { AuthService } from './shared/services/auth/auth.service';
import { LoginService } from './modules/login/service/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'stellantis-audit';
  isSpinnerActive = false;
  isSnackbarActive = false;

  constructor(
    public spinnerService: SpinnerService,
    public snackbarService: SnackbarService,
    private readonly ref: ChangeDetectorRef,
    private readonly translate: I18nService,
    public appService: AppService,
    private tokenInterceptor: TokenInterceptor,
    private loginService: LoginService,
    private readonly navigationService: NavigationService, // DO NOT DELETE
  ) {}

  ngOnInit() {
    this.translate.initI18nService();

    // this.spinnerService.getIsSpinnerActive().subscribe(isLoading => {
    //   this.isSpinnerActive = isLoading;
    //   this.ref.detectChanges();
    // });

    this.snackbarService.getIsSnackbarActive().subscribe(isOpen => {
      this.isSnackbarActive = isOpen;
      this.ref.detectChanges();
    });
    //WIP REDIRECT TO LOGIN
    this.tokenInterceptor.loginIntercept();


  }
}
