<div [class]="cssClass + ' page-footer-component'" >
  <s-button
    *ngIf="haveSecondary && !iconSecondary"
    [class]="cssClassSecondary + ' footer-button white-background'"
    [label]="labelSecondary | translate"
    [type]="typeSecondary"
    [disabled]="disabledSecondary"
    (clickEvent)="clickEventSecondary.emit($event)"
  ></s-button>
  <s-button
    *ngIf="haveSecondary && iconSecondary"
    [class]="cssClassSecondary + ' footer-button white-background'"
    [label]="labelSecondary | translate"
    [type]="typeSecondary"
    [disabled]="disabledSecondary"
    [icon]="iconSecondary"
    (clickEvent)="clickEventSecondary.emit($event)"
  ></s-button>

  <s-button
    *ngIf="haveTertiary && !iconTertiary"
    [class]="cssClassTertiary + ' footer-button white-background'"
    [label]="labelTertiary | translate"
    [type]="typeTertiary"
    [disabled]="disabledTertiary"
    (clickEvent)="clickEventTertiary.emit($event)"
  ></s-button>
  <s-button
    *ngIf="haveTertiary && iconTertiary"
    [class]="cssClassTertiary + ' footer-button white-background'"
    [label]="labelTertiary | translate"
    [type]="typeTertiary"
    [disabled]="disabledTertiary"
    [icon]="iconTertiary"
    (clickEvent)="clickEventTertiary.emit($event)"
  ></s-button>

  <s-button
    *ngIf="havePrimary && !iconPrimary"
    [class]="cssClassPrimary + ' footer-button primary-background'"
    [label]="labelPrimary | translate"
    [type]="typePrimary"
    [disabled]="disabledPrimary"
    [iconUrl]="iconUrlPrimary"
    (clickEvent)="clickEventPrimary.emit($event)"
  ></s-button>
  <s-button
    *ngIf="havePrimary && iconPrimary"
    [class]="cssClassPrimary + ' footer-button primary-background'"
    [label]="labelPrimary | translate"
    [type]="typePrimary"
    [disabled]="disabledPrimary"
    [icon]="iconPrimary"
    (clickEvent)="clickEventPrimary.emit($event)"
  ></s-button>
</div>