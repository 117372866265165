import { Injectable, Injector } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Router } from "@angular/router";
import { catchError, Observable, of, throwError } from "rxjs";
import { LoginService } from "./modules/login/service/login.service";
import { WebService } from "./shared/services/web-service/web.service";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor{

  constructor(private inject: Injector, private router: Router, private webService: WebService){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(x=> this.handleAuthError(x)));
  }

  loginIntercept() {
    console.log("INTERCEPTOR LOGIN TRIGGERED, get localstorageAccess_token", localStorage.getItem("ACCESS_TOKEN"))
    if(localStorage.getItem("ACCESS_TOKEN") == '' || !localStorage.getItem("ACCESS_TOKEN")) {
      console.log("INTERCEPTOR LOGIN NO ACCESS TOKEN")
      let service = this.inject.get(LoginService);
      service.refreshToken().subscribe({ 
        next: (res:any) => {
          /* debugger */
          //console.log("INTERCEPTOR: responseForDebugging: ", res)
          if (res?.access_token && res?.token_type) {
            //console.log("INTERCEPTOR: res.access_token && res.token_type exist: setting token")
            localStorage.setItem("ACCESS_TOKEN", res.token_type + " " + res.access_token);
            //console.log("INTERCEPTOR: token set")
          }
          return of("INTERCEPTOR: We refreshed the token now do again what u were trying to do");
        },
        error: (err:any) => {
          /* debugger */
        }
      });
      return of("INTERCEPTOR: Attempting to Refresh Tokens");
    }
    else {
      return throwError(() => new Error("INTERCEPTOR: Non Authentication Error"));
    } 
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    /* debugger */
    if(err && err.status === 401){
      let service = this.inject.get(LoginService);
      service.refreshToken().subscribe({ 
        next: (res:any) => {
          /* debugger */
          //console.log("responseForDebugging: ", res)
          if (res?.access_token && res?.token_type) {
            console.log("INTERCEPTOR: res.access_token && res.token_type exist: setting token")
            localStorage.setItem("ACCESS_TOKEN", res.token_type + " " + res.access_token);
            console.log("INTERCEPTOR: token set")
          }
          return of("INTERCEPTOR: We refreshed the token now do again what u were trying to do");
        },
        error: (err:any) => {
          /* debugger */
        }
      });
      return of("INTERCEPTOR: Attempting to Refresh Tokens");
    }
    else {
      return throwError(() => new Error("Non Authentication Error: ", err.error));
    } 
  }
}