import { Injectable } from '@angular/core';
import { WebService } from './shared/services/web-service/web.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {

  constructor(protected webService: WebService){}

}
