import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
  platformBrowserDynamic().bootstrapModule(AppModule)
  .then(ref => window.console.log = () => { })
  .catch(err => window.console.log = () => { });
} else {
  platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {}).catch(err => console.error(err));
}