<div
  [class]="cssClass + ' selectComponent'"
  [ngClass]="{ 'selectComponent--disabled': disabled }"
>
  <div class="selectComponent--label">
    <h6 *ngIf="label && !floatLabel">
      {{ required ? label.concat("*") : label }}
    </h6>
  </div>

  <mat-form-field appearance="outline">
    <mat-label *ngIf="label && floatLabel">{{
      required ? label.concat("*") : label
    }}</mat-label>

    <!-- Basic -->
    <mat-select
      *ngIf="!control && type === 'SELECT'"
      [multiple]="multiple"
      [placeholder]="placeholder ? placeholder : ''"
      [(ngModel)]="value"
      [disabled]="disabled ? disabled : false"
    >
      <s-input-text
        *ngIf="canFilter"
        class="filter"
        [appearance]="'fill'"
        [placeholder]="'Search'"
        [icon]="'search'"
        [clearable]="true"
        (changed)="filterEvent($event)"
      ></s-input-text>

      <mat-option
        *ngIf="multiple && filterValue == ''"
        [disabled]="disabled"
        [hidden]="hidden"
        [value]="'_ALL_'"
        #matOption
        (click)="toggleAll(matOption)"
        class="selectComponent--multiple"
        [ngClass]="{ 'selectComponent--selected': (value && value!.includes('_ALL_')) }"
        >{{'COMMON.ALL' | translate}}</mat-option
      >
      <ng-container *ngFor="let option of options; let i = index">
        <mat-option
          *ngIf="
            filterValue == '' ||
            option.value.toLowerCase().includes(filterValue.toLowerCase())
          "
          [disabled]="option.disabled || disabled"
          [hidden]="option.hidden || hidden"
          [value]="option.key"
          #matOption
          (click)="toggle(matOption, option.key)"
          [ngClass]="{
            'selectComponent--multiple': multiple,
            'selectComponent--last': options.length - 1 === i,
            'selectComponent--selected' : (value && value!.includes('_ALL_') && !option.disabled),
          }"
          >{{ option.value }}
        </mat-option>
      </ng-container>
    </mat-select>

    <mat-select
      *ngIf="control && type === 'SELECT'"
      [multiple]="multiple"
      [placeholder]="placeholder ? placeholder : ''"
      [formControl]="control"
      [required]="required ? required : false"
    >
      <s-input-text
        *ngIf="canFilter"
        class="filter"
        [appearance]="'fill'"
        [placeholder]="'Search'"
        [icon]="'search'"
        [clearable]="true"
        (changed)="filterEvent($event)"
      ></s-input-text>
      <!-- (iconClicked)="filterEvent($event)" -->

      <mat-option
        *ngIf="multiple && filterValue == ''"
        [disabled]="disabled"
        [hidden]="hidden"
        [value]="'_ALL_'"
        #matOption
        (click)="toggleAll(matOption)"
        class="selectComponent--multiple"
        [ngClass]="{ 'selectComponent--selected': (value && value!.includes('_ALL_')) }"
        >{{'COMMON.ALL' | translate}}</mat-option
      >
      <ng-container *ngFor="let option of options; let i = index">
        <mat-option
          *ngIf="
            filterValue == '' ||
            option.value.toLowerCase().includes(filterValue.toLowerCase())
          "
          [disabled]="option.disabled || disabled"
          [hidden]="option.hidden || hidden"
          [value]="option.key"
          #matOption
          (click)="toggle(matOption, option.key)"
          [ngClass]="{
            'selectComponent--multiple': multiple,
            'selectComponent--last': options.length - 1 === i,
            'selectComponent--selected' : (value && value!.includes('_ALL_') && !option.disabled),
          }"
          >{{ option.value }}</mat-option
        >
      </ng-container>
    </mat-select>

    <!-- Group -->
    <mat-select
      *ngIf="!control && type === 'GROUP'"
      [multiple]="multiple"
      [placeholder]="placeholder ? placeholder : ''"
      [(ngModel)]="value"
      [disabled]="disabled ? disabled : false"
    >
      <s-input-text
        *ngIf="canFilter"
        class="filter"
        [appearance]="'fill'"
        [placeholder]="'Search'"
        [icon]="'search'"
        [clearable]="true"
        (changed)="filterEvent($event)"
      ></s-input-text>
      <!-- (iconClicked)="filterEvent($event)" -->

      <s-button-toggle
        *ngIf="buttonToggleFilter"
        [cssClass]="'buttonToggleSelect ' + buttonToggleFilter.cssClass"
        [label]="buttonToggleFilter.label"
        [required]="buttonToggleFilter.required"
        [disabled]="buttonToggleFilter.disabled || disabled"
        [multiple]="buttonToggleFilter.multiple"
        [name]="buttonToggleFilter.name"
        [options]="buttonToggleFilter.options"
        [vertical]="buttonToggleFilter.vertical"
        [control]="buttonToggleFilter.control"
        [valuesSelected]="buttonToggleFilter.valuesSelected"
        (change)="buttonToggleFilterEvent($event)"
      ></s-button-toggle>

      <mat-optgroup *ngFor="let group of _groups" [label]="group.value" [disabled]="disabled || group.disabled">
        <mat-option
          *ngIf="multiple && filterValue == '' && !group.disabled" 
          [disabled]="disabled || group.disabled"
          [hidden]="hidden"
          [value]="'_ALL_'.concat(group.key)"
          #matOption
          (click)="toggleAll(matOption)"
          class="selectComponent--multiple selectComponent--group"
          [ngClass]="{
            'selectComponent--selected': value && value!.includes(group.key)
          }"
          >{{'COMMON.ALL' | translate}}
          <span style="display: none">
            {{ " " + group.value }}</span
          ></mat-option
        >
        <ng-container *ngFor="let option of group.options; let i = index">
          <mat-option
            *ngIf="
              filterValue == '' ||
              option.value.toLowerCase().includes(filterValue.toLowerCase())
            "
            [disabled]="option.disabled || disabled"
            [hidden]="option.hidden || hidden"
            [value]="group.key.concat('_').concat(option.key)"
            #matOption
            (click)="toggle(matOption, group.key)"
            [ngClass]="{
              'selectComponent--group': true,
              'selectComponent--multiple': multiple,
              'selectComponent--last': group.options.length - 1 === i,
              'selectComponent--selected' : (value && value!.includes('_ALL_'.concat(group.key)) && !option.disabled),
            }"
            ><span style="display: none"> {{ group.value + " " }}</span
            ><span>{{ option.value }}</span></mat-option
          >
        </ng-container>
      </mat-optgroup>
    </mat-select>

    <mat-select
      *ngIf="control && type === 'GROUP'"
      [multiple]="multiple"
      [placeholder]="placeholder ? placeholder : ''"
      [formControl]="control"
      [required]="required ? required : false"
    >
      <s-input-text
        *ngIf="canFilter"
        class="filter"
        [appearance]="'fill'"
        [placeholder]="'Search'"
        [icon]="'search'"
        [clearable]="true"
        (changed)="filterEvent($event)"
      ></s-input-text>
      <!-- (iconClicked)="filterEvent($event)" -->

      <s-button-toggle
        *ngIf="buttonToggleFilter"
        [cssClass]="'buttonToggleSelect ' + buttonToggleFilter.cssClass"
        [label]="buttonToggleFilter.label"
        [required]="buttonToggleFilter.required"
        [disabled]="buttonToggleFilter.disabled || disabled"
        [multiple]="buttonToggleFilter.multiple"
        [name]="buttonToggleFilter.name"
        [options]="buttonToggleFilter.options"
        [vertical]="buttonToggleFilter.vertical"
        [control]="buttonToggleFilter.control"
        [valuesSelected]="buttonToggleFilter.valuesSelected"
        (change)="buttonToggleFilterEvent($event)"
      ></s-button-toggle>

      <mat-optgroup *ngFor="let group of _groups" [label]="group.value" [disabled]="disabled || group.disabled">
        <mat-option
          *ngIf="multiple && filterValue == '' && !group.disabled"
          [disabled]="disabled || group.disabled"
          [hidden]="hidden"
          [value]="'_ALL_'.concat(group.key)"
          #matOption
          (click)="toggleAll(matOption)"
          class="selectComponent--multiple selectComponent--group"
          [ngClass]="{
            'selectComponent--selected': value && value!.includes(group.key) && !group.disabled
          }"
          >{{'COMMON.ALL' | translate}}
          <span style="display: none"> {{ " " + group.value }}</span>
        </mat-option>
        <ng-container *ngFor="let option of group.options; let i = index">
          <mat-option
            *ngIf="
              filterValue == '' ||
              option.value.toLowerCase().includes(filterValue.toLowerCase())
            "
            [disabled]="option.disabled || disabled"
            [hidden]="option.hidden || hidden"
            [value]="group.key.concat('_').concat(option.key)"
            #matOption
            (click)="toggle(matOption, group.key)"
            [ngClass]="{
              'selectComponent--group': true,
              'selectComponent--multiple': multiple,
              'selectComponent--last': group.options.length - 1 === i,
              'selectComponent--selected' : (value && value!.includes('_ALL_'.concat(group.key)) && !option.disabled),
            }"
            >{{ option.value }}</mat-option
          >
        </ng-container>
      </mat-optgroup>
    </mat-select>

    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error *ngIf="control && control.invalid">{{
      utils.getControlValidationErrorsLabel(control)
    }}</mat-error>
  </mat-form-field>
</div>
