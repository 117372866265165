import {Component, Input} from '@angular/core';

@Component({
  selector: 's-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class SLogoComponent {
  @Input() cssClass? = '';
  @Input() label?: string;
}
