import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { FormControl } from '@angular/forms';

@Component({
  selector: 's-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
})
export class SButtonToggleComponent implements OnInit {
  @Input() cssClass? = '';
  @Input() label? = '';
  @Input() required? = false;
  @Input() disabled? = false;
  @Input() multiple? = false;
  @Input() name? = '';
  @Input() options: ButtonToggleInterface[] = [];
  @Input() vertical? = false;
  @Input() control?: FormControl;
  @Input() valuesSelected?: any[] | string;

  @Output() change: EventEmitter<MatButtonToggleChange> = new EventEmitter<MatButtonToggleChange>();

  constructor() {}

  ngOnInit(): void {
  }
}

export interface ButtonToggleInterface {
  key: any;
  value?: string;
  disabled?: boolean;
  hidden?: boolean;
  enabled?: boolean;
}