import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { SpinnerService } from '../../components/spinner/spinner.service';

export class MockWebService {
  constructor(
    private readonly http: HttpClient,
    private readonly spinnerService: SpinnerService
  ) {}

  public async getMockResponse(serviceUrl: string): Promise<any> {
    this.spinnerService.setIsSpinnerActive(true);

    await this._fakeDelay();

    const source$ = this.http
      .get<any>(this._getViewServiceByUrl(serviceUrl))
      .pipe(
        tap(() => this.spinnerService.setIsSpinnerActive(false)),
        catchError((err: HttpErrorResponse) => {
          this.spinnerService.setIsSpinnerActive(false);
          throw err;
        })
      );
    return await lastValueFrom(source$);
  }

  private async _fakeDelay(seconds = 1) {
    return await new Promise((resolve) => setTimeout(resolve, seconds * 1000));
  }

  private _getViewServiceByUrl(serviceUrl: string): string {
    if (serviceUrl.includes('/api-example/')) {
      return '../../../../assets/api-mock/api-example/api-example.json';
    }

    if (serviceUrl.includes('/login/')) {
      return '../../../../assets/api-mock/login/login.json';
    }

    if (serviceUrl.includes('/login-fake-BE/')) {
      return '../../../../assets/api-mock/login/login-fake-BE.json';
    }

    if (serviceUrl.includes('/lateral-menu-fake-BE/')) {
      return '../../../../assets/api-mock/dashboard/lateral-menu/lateral-menu-fake-BE.json';
    }

    if (serviceUrl.includes('/exception-list-fake-BE/')) {
      return '../../../../assets/api-mock/dashboard/exception-list-page/exception-list-fake-BE.json';
    }

    if (serviceUrl.includes('/exception-list-col-fake-BE/')) {
      return '../../../../assets/api-mock/dashboard/exception-list-page/exception-list-col-fake-BE.json';
    }

    if (serviceUrl.includes('/review-page-table-columns-fake-BE/')) {
      return '../../../../assets/api-mock/dashboard/review-page/review-page-table-columns-fake-BE.json';
    }

    if (serviceUrl.includes('/exception-vehicle-filter/')) {
      return '../../../../assets/api-mock/dashboard/exception-page/exception-vehicle-filter.json';
    }

    if (serviceUrl.includes('/exception-ecu-filter/')) {
      return '../../../../assets/api-mock/dashboard/exception-page/exception-ecu-filter.json';
    }

    if (serviceUrl.includes('/exception-did-filter/')) {
      return '../../../../assets/api-mock/dashboard/exception-page/exception-did-filter.json';
    }

    if (serviceUrl.includes('/exception-issue-type/')) {
      return '../../../../assets/api-mock/dashboard/exception-page/exception-issue-type.json';
    }

    if (serviceUrl.includes('/api-DIDs/')) {
      return '../../../../assets/api-mock/dashboard/api-discussed/api-DIDs.json';
    }

    if (serviceUrl.includes('/api-vehicles/')) {
      return '../../../../assets/api-mock/dashboard/api-discussed/api-vehicles.json';
    }
    ///Fake Agreed Responses
    if (serviceUrl.includes('/ecu-list/')) {
      return '../../../../assets/api-mock/dashboard/api-agreed/ecu-list.json';
    }
    if (serviceUrl.includes('/did-list/')) {
      return '../../../../assets/api-mock/dashboard/api-agreed/did-list.json';
    }
    if (serviceUrl.includes('/notification-count/')) {
      return '../../../../assets/api-mock/dashboard/api-agreed/notification-count.json';
    }
    if (serviceUrl.includes('/exception-brands/')) {
      return '../../../../assets/api-mock/dashboard/api-agreed/exception-brands.json';
    }
    if (serviceUrl.includes('/exception-brands-pending/')) {
      return '../../../../assets/api-mock/dashboard/api-agreed/exception-brands-pending.json';
    }
    if (serviceUrl.includes('/exception-details/')) {
      return '../../../../assets/api-mock/dashboard/api-agreed/exception-details.json';
    }
    if (serviceUrl.includes('/exception-details-pending/')) {
      return '../../../../assets/api-mock/dashboard/api-agreed/exception-details-pending.json';
    }
    if (serviceUrl.includes('/brands/')) {
      return '../../../../assets/api-mock/dashboard/api-agreed/brands.json';
    }
    if (serviceUrl.includes('/post/')) {
      return '../../../../assets/api-mock/dashboard/api-agreed/post.json';
    }
    if (serviceUrl.includes('/patch/')) {
      return '../../../../assets/api-mock/dashboard/api-agreed/patch.json';
    }
    return '';
  }
}
