<div [class]="cssClass + ' checkBoxComponent'">
  <mat-checkbox
    *ngIf="!control"
    [(ngModel)]="value"
    [(indeterminate)]="indeterminate"
    [labelPosition]="labelPosition"
    [disabled]="disabled"
    (change)="changed.emit(value)">
    {{required ? label.concat('*') : label}}
  </mat-checkbox>

  <mat-checkbox
    *ngIf="control"
    [formControl]="control"
    [(indeterminate)]="indeterminate"
    [labelPosition]="labelPosition"
    [disabled]="disabled"
    (change)="changed.emit(control.value)">
    {{required ? label.concat('*') : label}}
  </mat-checkbox>

  <mat-hint *ngIf="hint">{{hint}}</mat-hint>
  <mat-error *ngIf="control && control.invalid">{{utils.getControlValidationErrorsLabel(control)}}</mat-error>

</div>
