export class StorageClass {
    private _store: StoreInterface = {
        currentRoute: undefined,
        // ...
    };

    constructor(){}

    getAll() {
        return this._store;
    }

    setAll(store: StoreInterface, clearOtherProp = true) {
        if (clearOtherProp) {
            this.clearStore();
        }

        if (store.currentRoute) {
            this._store.currentRoute = store.currentRoute;
        }
        // continue with other...
    }

    clearStore() {
        this._store = {
            currentRoute: undefined,
            // ...
        };
    }

    getCurrentRoute() {
        return this._store.currentRoute;
    }

    setCurrentRoute(currentRoute: string) {
        this._store.currentRoute = currentRoute;
    }
}

export interface StoreInterface {
    currentRoute?: string;
    // ...
}