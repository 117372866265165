import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private readonly _isSpinnerActive: BehaviorSubject<boolean>;
  private readonly _isExceptionListPageSpinnerActive: BehaviorSubject<boolean>;

  constructor() {
    this._isSpinnerActive = new BehaviorSubject<boolean>(false);
    this._isExceptionListPageSpinnerActive = new BehaviorSubject<boolean>(false);
  }

  getIsSpinnerActive() {
    return this._isSpinnerActive.asObservable();
  }

  setIsSpinnerActive(value: boolean) {
    this._isSpinnerActive.next(value);
  }

  getIsExceptionListPageSpinnerActive() {
    return this._isExceptionListPageSpinnerActive.asObservable();
  }

  setIsExceptionListPageSpinnerActive(value: boolean) {
    this._isExceptionListPageSpinnerActive.next(value);
  }
}
