import {
  Component,
  EventEmitter,
  Input,
  /*  OnDestroy, */ OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { Utils } from 'src/app/utils/utils';
import { ButtonToggleInterface } from '../button-toggle/button-toggle.component';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
// import { Subject, Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 's-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SSelectComponent implements OnInit /* , OnDestroy */ {
  @Input() cssClass?: string = '';
  @Input() label?: string = '';
  @Input() required?: boolean = false;
  @Input() disabled?: boolean = false;
  @Input() hidden?: boolean = false;
  @Input() floatLabel?: boolean = false;
  @Input() canFilter?: boolean = true;
  @Input() buttonToggleFilter?: ButtonToggleFilterInterface;
  @Input() multiple?: boolean = false;
  @Input() value?: string[] = [];
  @Input() options: SelectInterface[] | SelectGroupInterface[] = [];
  @Input() placeholder?: string = '';
  @Input() hint?: string;
  @Input() control?: FormControl;
  @Input() type: 'SELECT' | 'GROUP' = 'SELECT';
  @Input() name?: string = "";

  @Output() changed: EventEmitter<SelectEmitterInterface> =
    new EventEmitter<SelectEmitterInterface>();
  // @Output() filterEvent: EventEmitter<string> = new EventEmitter<string>();

  _groups: SelectGroupInterface[] = [];
  /* private modelChanged: Subject<string> = new Subject<string>();
  private subscription: Subscription;
  debounceTime = 1000; */
  filterValue: string = '';
  //selectedOptions: string[] = [];

  constructor(public utils: Utils) {
    /* this.subscription = this.modelChanged
      .pipe(
        debounceTime(this.debounceTime),
      )
      .subscribe((text) => {
        this.filterEvent.emit(text);
      }); */
  }

  ngOnInit(): void {
    if (this.type === 'GROUP') {
      this._initGroups();
    }
    // this.selectedOptions = this.value ? this.value : [];
  }

  private _initGroups() {
    this._groups = [...(this.options as SelectGroupInterface[])];
  }

  filterEvent(event: string) {
      this.filterValue = event;
  }

  buttonToggleFilterEvent(event: MatButtonToggleChange) {
    this._initGroups();

    if (event.value !== '_ALL_') {
      this._groups = this._groups.filter((g) => g.key === event.value);
    }
  }

  // THIS CSS VERSION DISPLAY AND PASSES ONLY THE "All" VALUE WHEN _ALL_ IS SELECTED. OPTIONS ARE CHECKED BECAUSE THEIR CSS RESPONDS TO THE selectAll BOOLEAN
  // THE ORIGINAL VALUE VERSION DISPLAYS AND PASSES _ALL_ VALUES FROM THE SELECT WHEN _ALL_ IS SELECTED. OPTIONS ARE CHECKED BECAUSE OF THEIR VALUE.
  toggleAll(event: MatOption) {
    if (this.type === 'SELECT') {
      if (!event.selected) {
        this.value = [];
      } else {
        this.value = [...new Set(["_ALL_"])];
        if (this.control) {
          //this is needed because when we have a form control this function does not set its values automatically
          this.control.setValue(this.value)
        } 
      }
    } else {
      const groupKey = event.value.replace('_ALL_', '');
      if (!event.selected) {
        this.value = this.value?.filter((c: string) => !c.includes(groupKey));
      } else {
        this.value = [...new Set([...this.value!.filter(string => !string.includes(groupKey)), "_ALL_".concat(groupKey)])];
        //sets the value to include selections from other groups, and exclude previous selection from within the group where "All" is selected
        if (this.control) {
          //this is needed because when we have a form control this function does not set its values automatically
          this.control.setValue(this.value)
        }
      }
    }

    this.changed.emit({
      lastSelected: event.value,
      value: this.value,
      control: this.control,
    });
  }

  toggle(event: MatOption, key: string) {
    if (!(typeof this.value === 'string') && !(typeof this.control?.value === 'string')) {
      if ('options' in this.options) {
        this.value = this.value?.filter(
          (c: string) => !c.includes('_ALL_'.concat(key))
        );
      } else if (event.group) { 
        //this deals with the select groups
        this.value = this.value?.filter(
          (c: string) => !c.includes('_ALL_'.concat(key))
        );
      } else if (this.control) {
        this.value = this.value?.filter((c: string) => !c.includes('_ALL_')); //both these lines are needed for correct functioning
        this.control.setValue(this.control.value.filter((c: string) => !c.includes('_ALL_'))) //both these lines are needed for correct functioning
      } else {
        this.value = this.value?.filter((c: string) => !c.includes('_ALL_'));
      }
    }

    this.changed.emit({
      lastSelected: event.value,
      value: this.value,
      control: this.control,
    });
  }

  /* filterChanged(event: any) {
    this.modelChanged.next(event.target.value);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  } */
}

export interface SelectInterface {
  key: string;
  value: string;
  disabled?: boolean;
  hidden?: boolean;
  enabled?: boolean;
}

export interface SelectGroupInterface extends SelectInterface {
  options: SelectInterface[];
}

export interface SelectGroupWithinGroupInterface extends SelectInterface {
  options: SelectGroupInterface[];
}

export interface SelectEmitterInterface {
  lastSelected: string;
  value: string | string[] | undefined;
  control: FormControl | undefined;
}

export interface ButtonToggleFilterInterface {
  cssClass?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  name?: string;
  options: ButtonToggleInterface[];
  vertical?: boolean;
  control?: FormControl;
  valuesSelected?: any[] | string;
}

export interface SelectComponentInterface {
  type: 'SELECT' | 'GROUP';
  options: SelectInterface[] | SelectGroupInterface[];
  label?: string;
  required?: boolean;
  disabled?: boolean;
  floatLabel?: boolean;
  canFilter?: boolean;
  buttonToggleFilter?: ButtonToggleFilterInterface;
  multiple?: boolean;
  value?: string[];
  placeholder?: string;
  hint?: string;
  name?: string;
  control?: FormControl;
}
