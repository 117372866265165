<div [class]="cssClass + ' logoComponent'">
  <img
    class="logo"
    [src]="'assets/images/logoStellantisBig.svg'"
    alt="logo"
    />
  <div *ngIf="label" class="logoSeparator"></div>
  <div *ngIf="label" style="margin-top: 16px">
    <span class="auditLabel">{{label}}</span>
  </div>
</div>
