import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  ButtonToggleFilterInterface,
  SSelectComponent,
  SelectComponentInterface,
  SelectEmitterInterface,
  SelectGroupInterface,
  SelectInterface,
} from '../select/select.component';
import { FormControl } from '@angular/forms';
import { ButtonToggleInterface } from '../button-toggle/button-toggle.component';

@Component({
  selector: 's-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.scss'],
})
export class SSelectGroupComponent {
  @Input() cssClass? = '';
  @Input() label? = '';
  @Input() elements: Array<SelectComponentInterface> = [];
  @Input() required = false;
  @Input() disabled?: boolean = false;

  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren(SSelectComponent) sSelectComponent:
    | QueryList<SSelectComponent>
    | undefined;

  constructor() {
  }

  changedEvent(event: SelectEmitterInterface, indexSelect: number) {
    let selectedValues = event.value as string[];
    for (let index = 0; index < this.elements.length; index++) {
      if (index > indexSelect) {
        //operates only on the selects that follow the one in use
        this.elements[index].options = this.elements[index].options.map(
          (option) => {
            let isInSelectedGroup = selectedValues.find((element: string) =>
              element.includes(option.key)
            );
            if (!isInSelectedGroup) {
              //disables all options that do not have the selected key
              if ('options' in option) {
                //this deals with selects of type "Group", which contain other options
                option.options = option.options.map((subOption) => {
                  return {
                    ...subOption,
                    disabled:
                      event.lastSelected === '_ALL_' &&
                      option.enabled &&
                      subOption.enabled
                        ? false
                        : true,
                    hidden: event.lastSelected === '_ALL_' ? false : true,
                  };
                });
              }
              return {
                ...option,
                disabled:
                  event.lastSelected === '_ALL_' && option.enabled
                    ? false
                    : true,
                hidden: event.lastSelected === '_ALL_' ? false : true,
              };
            } else {
              //enables all options that have the selected key
              if ('options' in option && index === 2) {
                //THIS IF BLOCK IS A CUSTOM FEATURE SPECIFIC TO THE DATA AT HAND.
                //THIS ENSURES THAT ONLY THE MODEL YEARS OF THE THIRD SELECT BOX IN STEP1 OF EXCEPTION FORM THAT MATCH THE SELECTED MODEL ARE ENABLED
                option.options = option.options.map((subOption) => {
                  //this is needed to match the options in the third select with those in the second
                  //because the value of the model is not directly accessible from the key of the option
                  const splitSubOption = subOption.key.split('_');
                  const model: string = splitSubOption[0];

                  // let isInSelectedSubGroup = selectedValues.some(
                  //   (string: string) => string.includes(model)
                  // );
                  let isInSelectedSubGroup = selectedValues.some(
                    (string: string) => string === `${option.key}_${model}` //this checks for the exact match, otherwise options like "JEEP_GRAND CHEROKEE" and "JEEP_CHEROKEE" remains both enabled
                  );
                  let isAllSubGroupSelected = selectedValues.some(
                    (string: string) => string.includes('_ALL_' + option.key)
                  );
                  if (isInSelectedSubGroup || isAllSubGroupSelected) {
                    return {
                      ...subOption,
                      disabled:
                        (!subOption.enabled || !option.enabled) ? true : false,
                      hidden: false,
                    };
                  } else {
                    return {
                      ...subOption,
                      disabled: true,
                      hidden: true,
                    };
                  }
                });
              }
              if ('options' in option && index !== 2) {
                option.options = option.options.map((subOption) => {
                  return {
                    ...subOption,
                    disabled:
                      !subOption.enabled || !option.enabled ? true : false,
                    hidden: false,
                  };
                });
              }
              return {
                ...option,
                disabled: (!option.enabled) ? true : false,
                hidden: false,
              };
            }
          }
        );
    //This part deals with the button toggle
        if (this.elements[index].buttonToggleFilter) {
          this.elements[index].buttonToggleFilter!.options = this.elements[
            index
          ].buttonToggleFilter!.options.map((option) => {
            let isInSelectedGroup = selectedValues.find((element: string) =>
              element.includes(option.key)
            );
            if (!isInSelectedGroup && option.key !=='_ALL_') {
              return {
                ...option,
                disabled:
                  event.lastSelected === '_ALL_' && option.enabled && option.key !== '_ALL_'
                    ? false
                    : true,
                hidden: event.lastSelected === '_ALL_' ? false : true,
              };
            } else {
              return {
                ...option,
                disabled: (!option.enabled && option.key !== '_ALL_') ? true : false,
                hidden: false,
              };
            }
          });
        }

        this.sSelectComponent!.get(index)!.options = this.elements[index].options;
        if (this.sSelectComponent!.get(index)!.buttonToggleFilter) {
          this.sSelectComponent!.get(index)!.buttonToggleFilter = this.elements[index].buttonToggleFilter;
        }
        this.sSelectComponent?.get(index)?.ngOnInit();
      }
    }
    const selectData = { event, indexSelect };
    this.changed.emit(selectData);
  }
}
